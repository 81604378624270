import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useHistory} from  "react-router-dom"
import { Audio } from 'react-loader-spinner'
import Button from '@material-ui/core/Button';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {useDispatch } from 'react-redux';
import {changeTheme,handleDarkTheme} from '../../redux/actions/themeAction'
import LogoSVG from '../../assets/images/logoSVG'


const web3 = require('web3');


export default function ButtonAppBar({setSwapFlag,setBalance,showConnectorButton,setAccConnected}) {
    const history = useHistory()
    const [buttonLabel, setButtonLabel] = useState(!JSON.parse(localStorage.getItem("metamaskacc")) ?'Connect to Meta Mask' : 'Connected')
    const [loader, setLoader] = useState(false)
    const [lightTheme, setLightTheme] = useState(true)
    const accConnected = JSON.parse(localStorage.getItem("metamaskacc") )
    const dispatch = useDispatch()

const connectToMetaMask = async () => {
  setLoader(true)
  
   if (typeof window.ethereum !== "undefined") {
        try {
           const acc = await window.ethereum.request({ method: "eth_requestAccounts" });
            let  web3instnce = new web3(window.ethereum);
            await window.ethereum.enable();
            var accounts = await web3instnce.eth.getAccounts();
            const number = await web3instnce.eth.getBalance(accounts[0])
            const balance = await web3instnce.utils.fromWei(number) 
            balance && localStorage.setItem('metamaskBal',JSON.stringify(balance))
            if (acc) { 
              localStorage.setItem('metamaskacc',JSON.stringify(acc));
              setAccConnected(acc)
            }
            // setBalance(balance)
            setSwapFlag(true)
            setLoader(false)
            setButtonLabel('Connected')
            console.log('metamask connected')
            return balance
            
        } catch (error) {
          setLoader(false)
        }
    } 
    else {
        console.log('meta mask not installed')
        setLoader(false)
        setButtonLabel("Please install MetaMask")
    }
}

const disconnectMetaMask = async() => {
  setLoader(true)
  try {
    window.ethereum.selectedAddress = null;
    console.log('Metamask disconnected');
    localStorage.removeItem('metamaskacc');
    localStorage.removeItem('metamaskBal')
    setAccConnected(false)
    setLoader(false)
    setButtonLabel('Connect to Meta Mask')
  } catch (error) {
    console.log(error);
    setLoader(false)
  }

}

const handleThemeChange = () => {
  lightTheme ?
  dispatch(handleDarkTheme())
  :
  dispatch(changeTheme())
  
  setLightTheme(!lightTheme)
}

  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static"
      sx={{
        backgroundColor:'transparent'
      }}
      >
        <Toolbar>
        <LogoSVG/>
          <>
          <Typography variant="h6" component="div" sx={{ cursor:'pointer', marginLeft:2}}
          onClick={()=> history.push('/')}
          >
            Swap
          </Typography>
          <Typography variant="h6" component="div" sx={{cursor:'pointer',marginLeft:2}}
          onClick={()=> history.push('/analytic')}
          >
            Analytics
          </Typography>
          <span style={{flexGrow:1}}></span>
          </>
          {lightTheme ?
          <Brightness3Icon sx={{marginRight:2,cursor:'pointer'}} 
          onClick={handleThemeChange}
          />
          :
          <WbSunnyIcon  sx={{marginRight:2,cursor:'pointer'}} 
          onClick={handleThemeChange}
          />
        }

          {showConnectorButton &&
          <Button
          variant="contained"
          color="primary"
          style={{
            padding:10,borderRadius:7,cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',}}
            sx={{color:"secondary"}}
          onClick={()=> !accConnected ? connectToMetaMask() : disconnectMetaMask()}
          >
          <span>
            {buttonLabel} 
          </span>
          {loader &&
          <span style={{marginLeft:5}}>
          <Audio
              height="15"
              radius="9"
              color="white"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
              text-align="center"
              width="100%"
          />
          </span>
          }
          </Button>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
