import React from 'react'
import './styles.css'
import { Audio } from 'react-loader-spinner'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const CurrencyTable = ({
    data,
    marketFlag,
    pageNum,
    setPageNum,
    loader,
    setMarketPages,
    marketPages,
    marketCount,
    tokenCount,
}) => {
    return (
        <div 
        className="row"
        style={{marginTop:10}}
        >
					<div 
                    className="col-xl-12 "
                    style={{borderRadius:10}}
                    >
        <div 
        className='table-responsive table-hover fs-14 tableHeight'
        style={{borderRadius:10}}
        >
            <table
                className='table display mb-4 dataTablesCard font-w600  border-no card-table text-black' 
                id='example6'
            >
                <thead>
                <tr 
                className='headerRow'
                style={{backgroundColor:'lightgray'}}
                >
                    <td className='wspace-no columnWidth'>Chain</td>
                    <td className='wspace-no columnWidth'>Name</td>
                    <td className='wspace-no columnWidth'>{marketFlag ? 'APR' : 'Price'}</td>
                    <td className='wspace-no columnWidth'>{marketFlag ? 'TVL' : 'Liquidity'}</td>
                    <td className='wspace-no columnWidth'>Volume{marketFlag && '(24h)'}</td>
                    {marketFlag &&
                        <>
                            <td className='wspace-no columnWidth'>{'Volume(7d)'}</td>
                            <td className='wspace-no columnWidth'>{'Fees(24h)'}</td>
                            <td className='wspace-no columnWidth'>{'Fees(7d)'}</td>
                        </>
                    }
                </tr>
                </thead>
                {
                data.length ?
                data.map((data) => {
                    return (
                        <tr>
                            <td
                            className=' rank-ic fs-20 columnWidth'
                            >
                                <img src={require('../../assets/images/ethereumLogo.png')}
                                    alt="eth"
                                    style={{ width: 45, height: 45, margin: 10 }}
                                />
                            </td>

                            <td 
                            className='wspace-no columnWidth'
                            >
                                {!marketFlag ?
                                     <div> 
                                        <div>
                                            <img
                                                style={{ width: 20, height: 20, position: 'relative', top: 10,}}
                                                src={`https://res.cloudinary.com/sushi-cdn/image/fetch/f_auto,c_limit,w_48,q_auto/https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/ethereum/${data?.id?.split(':').pop(data?.id?.indexOf('0'))}.jpg`} 
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src=require('../../assets/images/ethereumLogo.png');
                                                  }}
                                                  alt='img'
                                                />
                                                <span style={{marginLeft :10}}>
                                            {data?.symbol}
                                            </span>
                                        </div>
                                        <div 
                                        style={{ fontSize: 13, color: 'grey', marginLeft: 30 }}
                                        >
                                            {data?.name}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            {data?.name}
                                        </div>
                                        <div style={{ fontSize: 13, color: 'grey' }}>
                                            {data?.farm ? 'Farm' : 'Pool'}
                                        </div>
                                    </div>
                                }
                            </td>

                            <td  className='wspace-no columnWidth'>
                                {!marketFlag ?
                                    `${data?.price?.derivedNative.substring(0, 7)}M $`
                                    :
                                    data?.apr.length > 3 ? `${(data?.apr * 100).toString().substring(0, 4)}%` : '0.00%'
                                }
                                </td>
                            <td className='wspace-no columnWidth'>
                                {!marketFlag ?
                                    `${data?.liquidityUSD.substring(0, 3)}.${data?.liquidityUSD.substring(4, 6)}M $`
                                    :
                                    `${data?.liquidityUSD.substring(0, 2)}.${data?.liquidityUSD.substring(2, 4)}M $`
                                }
                            </td>
                            <td className='wspace-no columnWidth'>
                                {!marketFlag ?
                                    `${data?.volumeUSD.substring(0, 1)}.${data?.volumeUSD.substring(1, 4)}B $`
                                    :
                                    `${(data?.volume1d / 1000).toString().substring(0, 6)}k $`
                                }
                            </td>

                            {marketFlag &&
                                <>
                                    <td className='wspace-no columnWidth'>
                                        {(data?.volume1w / 1000000).toString().substring(0, 5)}k $
                                    </td>
                                    <td className='wspace-no columnWidth'>
                                        {(data?.fees1d / 1000).toString().substring(0, 4)}k $
                                    </td>
                                    <td className='wspace-no columnWidth'>
                                        {(data?.fees1w / 1000).toString().substring(0, 5)}k $
                                    </td>
                                </>
                            }
                        </tr>
                    )
                })
                :
                <tr>
                <td colSpan={!marketFlag ? 5 : 8}
                style={{height:'63vh'}}
                >
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'}}>
                    <>
                   {!loader && 'No More Data'} 
                    </>
                    </div>
                    </td>
                </tr>
                }
                {loader &&
                    <tr >
                        <td 
                        colspan={8}        
                        >
                            <Audio
                                height="20"
                                radius="9"
                                color="lightgrey"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                                text-align="center"
                                width="100%"
                            />
                        </td>
                    </tr>
                }
            </table>
            {/* {console.log(data.length)} */}
        </div>
        {marketFlag ?
                        <div 
                        style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'flex-end',
                            marginRight:10
                        }}
                        >
                            {marketPages > 0 &&
                                <span
                                    onClick={() => setMarketPages(marketPages - 1)}
                                    style={{ cursor: 'pointer' }}
                                    className='rightLeftIconContainer'
                                >
                                    <NavigateBeforeIcon color='primary.main'/>
                                </span>
                            }
                            {(marketPages + 1 <= data.length + 1 / 20) &&
                                <span
                                    onClick={() => setMarketPages(marketPages + 1)}
                                    style={{ cursor: 'pointer' }}
                                    className='rightLeftIconContainer'
                                >
                                    <NavigateNextIcon color='primary.main'/>
                                </span>
                            }
                        </div>
                        :
                        <div
                        style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'flex-end',
                            marginRight:10
                        }}
                        >
                            {pageNum > 0 &&
                                <span
                                    onClick={() => setPageNum(pageNum - 1)}
                                    style={{ cursor: 'pointer' }}
                                    className='rightLeftIconContainer'
                                >
                                    <NavigateBeforeIcon color='primary.main'/>
                                </span>
                            }
                            {(pageNum + 1<= (data.length + 1)/20 ) &&
                                <span
                                    onClick={() => setPageNum(pageNum + 1)}
                                    style={{ cursor: 'pointer' }}
                                    className='rightLeftIconContainer'
                                >
                                    <NavigateNextIcon color='primary.main'/>
                                </span>
                                
                            }
                        </div>
                    }
        </div>
        </div>
    )
}

export default CurrencyTable