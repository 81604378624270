export const changeTheme = (theme) => {
    return {
        type: 'CHANGE_THEME',
        payload: {theme}
    }
}

export const handleDarkTheme = (theme) => {
    return {
        type: 'DARK_THEME',
        payload: {theme}
    }
}