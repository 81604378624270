import React,{useState} from 'react'
import './styles.css'
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@material-ui/core/Button';

const CoinModal = ({data, setFlag,setVal,setCoinImage,setCurrencyType,setAddress}) => {

const [searchVal, setSearchVal] = useState('')
const [filteredArr, setFilteredArr] = useState(data)

const handleSearch = (e) => {
    setSearchVal(e.target.value)
    setFilteredArr(data.filter((elem)=>elem.symbol.toLowerCase().includes(e.target.value.toLowerCase())))
}

  return (
    <div >
        <div className='stickyHeader'>
        <div 
        className='header'
        >
        <div className='modalHeading'>
        Choose any coin
        </div>
        <Button
        variant="contained"
        color="primary" 
        style={{
            padding:3
        }}
        // className='iconContainer'
        onClick={()=>setFlag(false)}>
            <ClearIcon sx={{color:'white'}}/>
        </Button>
        
        </div>
        <div className='searchBar'>
            <input 
            type={'search'}
            value={searchVal}
            style={{width:'100%',outline:'none',border:'0px'}}
            placeholder='search'
            onChange={(e)=>handleSearch(e)}
            />
        </div>
        </div>

        <div style={{margin:20, cursor:'pointer'}}>
            {filteredArr.map((elem,index)=>{
                return(
                    <div key={index}
                    onClick={()=>{setVal(elem);setCoinImage(elem.logoURI);setFlag(false)}}
                    style={{margin:10,display:'flex',alignItems:'center'}}>
                        <img
                        src={elem.logoURI} 
                        style={{width:26,height:26}}
                        alt = 'logo'
                        />
                        <span style={{marginLeft:5}}>{elem.symbol}</span>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default CoinModal