import {Route, Switch, BrowserRouter} from "react-router-dom"
import Home from "./screens/Home/Home";
import Analytics from "./screens/Analytics/Analytics";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSelector } from 'react-redux';

function App() {
  const themeState = useSelector(state => state.themeReducer)
  return (
    <MuiThemeProvider theme={themeState} >
      <>
      <CssBaseline />
      <BrowserRouter>
      <Switch>
        <Route exact path='/' component = {Home}/>
        <Route path="/analytic" component = {Analytics}/>
        <Route component={Error}/>
      </Switch>
      </BrowserRouter>
      </>
    </MuiThemeProvider>
  );
}

export default App;
