import axios from 'axios'
import React,{useEffect,useState} from 'react'
import CurrencyTable from '../../components/currencyTable/CurrencyTable'
import Header from "../../components/homeHeader/Header"
import './styles.css'
import Button from '@material-ui/core/Button';
import { useCallback } from 'react'


const Analytics = () => {

const [data,setData] = useState([])
const [marketData, setMarketData] = useState([])
const [marketDataFlag, setMarketDataFlag] = useState(false)
const [pageNum, setPageNum] = useState(0)
const [loader, setLoader] = useState(false)
const [marketPages, setMarketPages] = useState(0)
const [marketCount, setMarketCount] = useState(0)
const [tokenCount, setTokenCount] = useState(0)
const [marketButtonFlag, setMarketButtonFlag] = useState(false)

const getAnalytics = useCallback(() => {
    setLoader(true)
    axios.get(`https://www.sushi.com/analytics/api/tokens?orderBy=liquidityUSD&orderDirection=desc&pagination={%22pageIndex%22:${pageNum},%22pageSize%22:20}&networks=[1]`).then((response)=>{

    setMarketDataFlag(false)
    setLoader(false)
    setData(response.data)
    
    })
},[pageNum])

const getTopMarkets = useCallback(() => {
    setLoader(true)
    axios.get(`https://www.sushi.com/analytics/api/pools?orderBy=liquidityUSD&orderDirection=desc&pagination={%22pageIndex%22:${marketPages},%22pageSize%22:20}&networks=[1]`)
    .then((response)=>{

        setMarketDataFlag(true)
        setLoader(false)
        setMarketData(response.data)
        setMarketButtonFlag(true)
    })
},[marketPages])

useEffect(()=>{
    getAnalytics()
},[pageNum,getAnalytics])

useEffect(()=>{
marketButtonFlag && getTopMarkets()
},[marketPages,marketButtonFlag,getTopMarkets])


const handleMarketButton = () => {

    getTopMarkets()
    setMarketCount(1)
    setTokenCount(0)
}

const handleAnalyticButton = () => {
    getAnalytics()
    setTokenCount(1)
    setMarketCount(0)
    setMarketButtonFlag(true)
}
  return (

   

    <div className='analyticsContainer'>
        <Header/>

        <div className='analyticsContentContainer'>
        <div className='analyticsHeading'>
            Anaylytics
        </div>
        <div className='subHeading'>
        Analytics platform for tracking the liquidity, volume, and fees generated by Fibi Swap.
        </div>
        <div className='buttonContainer'>
        <Button 
        variant="contained"
        color="primary"
        className='tokenButtons'
        style={{opacity : marketDataFlag && 0.7}} 
        onClick={handleMarketButton}
        >
                Top Markets
        </Button>
        <Button 
        variant="contained"
        color="primary" className='tokenButtons'
        style={{opacity : !marketDataFlag && 0.7,marginLeft:15}}
        onClick={handleAnalyticButton}
        >
            Top Token
        </Button>
        </div>
        <div className='subHeading'>
        Following are the list of Top {marketDataFlag === true ? 'Markets' : 'Tokens'}
        </div>
        <div style={{padding:10}}>
        <CurrencyTable 
        data={marketDataFlag === true ? marketData : data} 
        marketFlag = {marketDataFlag} 
        setPageNum={setPageNum}
        pageNum = {pageNum}
        loader = {loader}
        getAnalytics={getAnalytics}
        getTopMarkets = {getTopMarkets}
        setMarketPages = {setMarketPages}
        marketPages = {marketPages}
        marketCount={marketCount}
        tokenCount = {tokenCount}
        
        />
        </div>
        <div>
        </div>
        </div>   
    </div>
  )
}

export default Analytics