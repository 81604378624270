import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { blue } from "@mui/material/colors";

const initialState = (createMuiTheme({
    palette: {
        primary: {
          main: blue[800]
        },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundImage:
              'linear-gradient( #146ca4,#B0DCF9)',
          }
        }
      }
    }
  }));

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_THEME': return (createMuiTheme({
            palette: {
                primary: {
                  main: blue[800]
                },
            },
            overrides: {
              MuiCssBaseline: {
                "@global": {
                  body: {
                    backgroundImage:
                      'linear-gradient( #146ca4,#B0DCF9)',
                  }
                }
              }
            }
          }))

          case 'DARK_THEME': return (createMuiTheme({
            palette: {
                primary: {
                  main: purple[300]
                },
            },
            overrides: {
              MuiCssBaseline: {
                "@global": {
                  body: {
                    backgroundImage:
                      'linear-gradient(rgb(19, 19, 34), #473855);'
                  }
                }
              }
            }
          }))
        default: return state = initialState
    }
}

export default themeReducer